@import '../theme-palettes/light-theme-palette.scss';
@import '../colour-codes/cc-colours.scss';

// Define a material Light Theme
$light-theme: mat.define-light-theme((
  color: (
   primary: $cc-light-primary,
   accent:  $cc-light-accent,
   warn:    $cc-warn,
  ),
));

// Define CSS Light Theme colour Palette
.light-theme{
  --cc-primary-colour     : var(--cc-light-blue);
  --cc-alt-primary-colour : var(--cc-dark-blue);

  --cc-accent-colour      : var(--cc-light-grey);
  --cc-alt-accent-colour  : var(--cc-dark-grey);

  --cc-bg-colour          : var(--cc-white);
  --cc-portal-bg-colour   : var(--cc-light-grey);

  --cc-text-colour          : var(--cc-black);
  --cc-alt-text-colour      : var(--cc-white);
  --cc-disabled-text-colour : var(--cc-light-grey);
  --material-text-colour    : var(--cc-white);

  --cc-action-button-text   : var(--cc-white);
  --cc-action-button-disabled-text: white;
  --cc-dismiss-button-text : var(--cc-black);
  --cc-dismiss-button-disabled-text : var(--cc-light-grey);

  --cc-snackbar-default: var(--cc-black);
  --cc-snackbar-info-text-colour: var(--cc-light-blue);
  --cc-snackbar-info-bg-colour: var(--cc-light-grey);
  --cc-snackbar-default-bg-colour: var(--cc-white);
  --cc-snackbar-default-text-colour: var(--cc-black);

  --cc-success            : var(--cc-green);
  --cc-warning            : var(--cc-orange);
  --cc-error              : var(--cc-red);
  --cc-info               : var(--cc-light-blue);


  --cc-button-text-colour     : var(--cc-black);
  --cc-reset-button-bg-colour : var(--cc-white);
  --cc-reset-button-tx-colour : var(--cc-black);

  --cc-tab-tx-colour : var(--cc-white);
  --cc-tab-bg-colour : var(--cc-dark-blue);

  --cc-icon-colour : var(--cc-dark-grey);

  --cc-link               : var(--cc-dark-blue);
  --cc-link-visited       : var(--cc-dark-blue);
  --cc-link-active        : var(--cc-dark-blue);
  --cc-link-hover         : var(--cc-light-blue);



  
  // --cc-logo-small         : url("assets/computacenter/logos/logo_small_colour.png");
  --cc-logo-big           : url("/assets/logos/cc_logo_big_colour.png");
  --cc-loginPage-bg       : url("/assets/images/Hatfield.png");
  --cc-requestPage-bg     : url("/assets/images/Kerpen.png");
}
