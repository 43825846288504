@use '@angular/material' as mat;
@include mat.core();
// // Angular Material defines a mixin named core that includes prerequisite styles for common features used by multiple components, such as ripples.
// // The core mixin must be included exactly once for your application, even if you define multiple themes.
// // Including the core mixin multiple times will result in duplicate CSS in your application.

@import 'assets/themes/themes/dark-theme.scss';
@import 'assets/themes/themes/light-theme.scss';
@import 'assets/themes/typography/cc-typography.scss';
@import '~@angular/cdk/overlay-prebuilt.css';
// // Apply the dark theme by default
@include mat.core-theme($light-theme);
@include mat.all-component-themes($light-theme);  // If we dont do this it doesnt apply the theme, if keep it in moans about duplicate
@include mat.all-component-typographies($cc-typography-config);

// Apply the light theme only when the user prefers light themes.
@media (prefers-color-scheme: dark) {
  @include mat.all-component-colors($dark-theme);
}

// Apply Global CSS Styles here:
body {
  color: var(--cc-text-colour );
  background-color: var(--cc-portal-bg-colour);
}

// remove auto-complete light grey background
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--cc-text-colour);
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s !important;
}

// Only show 1 error message
mat-form-field {
  mat-error {
    display: none !important;
  }
  mat-error:first-child {
    display: block !important;
  }
}

//- a normal, unvisited link
a:link {
  color: var(--cc-link );
}

//a link the user has visited
a:visited {
  color: var(--cc-link-visited );
}

//a link when the user mouses over it
a:hover {
  color: var(--cc-link-hover );
}

//a link the moment it is clicked
a:active {
  color: var(--cc-link-active );
}

//mat stepper
.mat-step-icon-state-done,
.mat-step-icon-state-edit {
  background-color: var(--cc-green) !important;
}
.mat-stepper-label-position-bottom  {
  padding-top : 0px !important;
  padding-bottom: 0px !important;
  // padding-left: 10px !important;
}
.mat-stepper-vertical-line::before {
//   top: -10px !important;
//   left: -14px !important;
}

.mat-step-icon-selected {
  background-color: #009ddc !important;
}
.mat-step-header {
  padding-left: 5px !important;
  padding-right: 10px !important;
  pointer-events: none !important;
}

.mat-horizontal-content-container {
  overflow: hidden;
  padding: 0px 0px 0px 0px !important;
  margin-top: 5px;
  // height:40px
}

// mat table



.mat-mdc-tab-group.mat-background-accent,
.mat-mdc-tab-nav-bar.mat-background-accent {
    --mat-mdc-tab-header-with-background-foreground-color: var(--cc-tab-tx-colour);
}


// button {
//   color: var(--cc-button-text-colour) !important;
// }

button:disabled{
  color: var(--cc-disabled-text-colour) !important;
}

.reset-button {
  background-color: var(--cc-reset-button-bg-colour) !important;
  color: var(--cc-reset-button-tx-colour) !important;
}



// .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
//   padding-top: 8px !important;
//   padding-bottom: 8px !important;
//   text-align: center !important;

// }

// .mat-mdc-form-field-infix {
//   min-height: 10px !important;
//   text-align: center !important;
// }

// .mdc-text-field .mdc-floating-label {
//   position: relative !important;
//   top: 1.3em !important;
// }

// Snack Bar Themes

.edit-snack-bar {
  color: pink !important;
  background-color: goldenrod !important;
  --mdc-button__label : green;
}

.mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: var(--cc-snackbar-default) !important;
  &.snack-notification-error {
    --mdc-snackbar-container-color: var(--cc-bg-colour);
    --mdc-snackbar-supporting-text-color: var(--cc-red);
  }

  &.snack-notification-warning {
    --mdc-snackbar-container-color:var(--cc-bg-colour);
    --mdc-snackbar-supporting-text-color: var(--cc-orange);
  }

  &.snack-notification-success {
    --mdc-snackbar-container-color: var(--cc-bg-colour);
    --mdc-snackbar-supporting-text-color: var(--cc-green);
  }

  &.snack-notification-information {
    --mdc-snackbar-container-color: var(--cc-snackbar-info-text-colour);
    --mdc-snackbar-supporting-text-color:var(--cc-snackbar-info-bg-colour);
  }

  &.snack-notification-default {
    --mdc-snackbar-container-color: var(--cc-snackbar-default-bg-colour);
    --mdc-snackbar-supporting-text-color: var(--cc-snackbar-default-text-colour);
    --mdc-button__label : var(--cc-snackbar-default-text-colour)
    white-space: pre-wrap;
  }
}

::-webkit-scrollbar {
  width: 5; /* Remove scrollbar space */
  background: var(--cc-bg-colour); /* Optional: just make scrollbar invisible */
}

::-webkit-scrollbar-thumb {
  background: var(--cc-portal-bg-colour);
  border-radius:  30px;
  border: 5px solid var(--cc-bg-colour)
}

// Global Spinner Styles

.spinner-container {
  display: flex;
  flex-direction: column;
  margin: 50px;
  margin-top: 40px;
  margin-bottom: 10px;
}
.spinner {
  align-self: center;
}

.spinner-title {
  align-self: center;
  padding-left: 35px;
}

.mat-expansion-panel-body {
  background-color: var(--cc-balck) !important;
  padding: 0px !important;
}


.dialog-background{
  background-color: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(0px);
}

.mdc-linear-progress__buffer-bar {
  background-color: var(--cc-dark-blue) !important;
  height: 10px;
}
.mdc-linear-progress__bar-inner {
  border-color: var(--cc-light-blue) !important;
  height: 10px;
}

.mat-progress-bar-fill::after {
  background-color: #1eb980!important;
}

.mdc-checkbox-selected-checkmark-color{
  color: var(--cc-white);
}

.mat-datepicker-content {
  position: absolute;
  top: 180px; /* Adjust the top position as needed */
  left: 550px; /* Adjust the left position as needed */
}

