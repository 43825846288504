  *{
  // CC Colours
  --cc-light-blue : #009ddc;
  --cc-dark-blue  : #24387f;
  
  // CC Accent Colours
  --cc-light-grey : #bdbcbc;
  --cc-dark-grey  : #706f6f;

  // CC Text Colours
  --cc-black : #333333;
  --cc-white : #f5f5f5;

  // CC Info Colours
  --cc-green  : #8fd400;
  --cc-orange : #ff7900;
  --cc-red    : #f12938;
}