@import '../theme-palettes/dark-theme-palette.scss';
@import '../colour-codes/cc-colours.scss';


// Define a material Dark Theme
$dark-theme: mat.define-dark-theme((
 color: (
   primary: $cc-dark-primary,
   accent:  $cc-dark-accent,
   warn:    $cc-warn,

  //  foreground: $cc-dark-foreground,          - Look into these two options
  //  background: $cc-dark-theme-background,
 ),

));


// Define CSS Dark Theme colour Palette
.dark-theme{
  --cc-primary-colour     : var(--cc-dark-blue);
  --cc-alt-primary-colour : var(--cc-light-blue);

  --cc-accent-colour      : var(--cc-dark-grey);
  --cc-alt-accent-colour  : var(--cc-light-grey);

  --cc-bg-colour          : var(--cc-black);
  --cc-portal-bg-colour   : var(--cc-dark-grey);

  --cc-text-colour        : var(--cc-white);
  --cc-alt-text-colour    : var(--cc-black);
  --cc-disabled-text-colour : var(--cc-dark-grey);
  --cc-dismiss-button-disabled-text : var(--cc-dark-grey);

  --cc-snackbar-default: var(--cc-white);
  --cc-snackbar-info-text-colour: var(--cc-light-blue);
  --cc-snackbar-info-bg-colour: var(--cc-dark-grey);
  --cc-snackbar-default-bg-colour: var(--cc-black);
  --cc-snackbar-default-text-colour: var(--cc-white);

  --cc-success            : var(--cc-green);
  --cc-warning            : var(--cc-orange);
  --cc-error              : var(--cc-red);
  --cc-info               : var(--cc-white);

  --material-text-colour  : var(--cc-white);
  --cc-button-text-colour : var(--cc-white);
  --cc-action-button-text : var(--cc-white);
  --cc-action-button-disabled-text: var(--cc-light-grey);
  --cc-dismiss-button-text: var(--cc-white);

  --cc-reset-button-bg-colour : var(--cc-dark-grey);
  --cc-reset-button-tx-colour : var(--cc-black);

  --cc-icon-colour : var(--cc-light-grey);

  --cc-tab-tx-colour : var(--cc-white);
  --cc-tab-bg-colour : var(--cc-dark-blue);

  --cc-expansion-panel-content: var(--cc-dark-grey);
  --cc-link               : var(--cc-white);
  --cc-link-visited       : var(--cc-white);
  --cc-link-active        : var(--cc-white);
  --cc-link-hover         : var(--cc-light-blue);

  // --cc-logo-small         : url("assets/computacenter/logos/logo_small_white.png");
  --cc-logo-big           : url("/assets/logos/cc_logo_big_white.png");
  --cc-loginPage-bg       : url("/assets/images/Hatfield.png");
  --cc-requestPage-bg     : url("/assets/images/Kerpen.png");
}

