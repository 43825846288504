// A typography config is a collection of all typography levels. Angular Material represents this config as a Sass map.
// This map contains the styles for each level, keyed by name. You can create a typography config with the define-typography-config Sass function.
// Every parameter for define-typography-config is optional; the styles for a level will default to Material Design's baseline if unspecified.

//    Name	                        Description                                                   CSS Class
// headline-1   	One-off header, usually at the top of the page (e.g. a hero header).        .mat-headline-1
// headline-2	    One-off header, usually at the top of the page (e.g. a hero header).        .mat-headline-2
// headline-3	    One-off header, usually at the top of the page (e.g. a hero header).        .mat-headline-3
// headline-4	    One-off header, usually at the top of the page (e.g. a hero header).        .mat-headline-4
// headline-5	    Section heading corresponding to the <h1> tag.                              .mat-h1 or .mat-headline-5
// headline-6	    Section heading corresponding to the <h2> tag.                              .mat-h2 or .mat-headline-6
// subtitle-1	    Section heading corresponding to the <h3> tag.                              .mat-h3 or .mat-subtitle-1
// subtitle-2	    Section heading corresponding to the <h4> tag.                              .mat-h4 or .mat-subtitle-2
// body-1	        Base body text.                                                             .mat-body-1
// body-2	        Secondary body text.                                                        .mat-body-2
// caption	      Smaller body and hint text.                                                 .mat-small or .mat-caption
// button	        Buttons and anchors

$cc-typography-config: mat.define-typography-config(
  $headline-1: mat.define-typography-level($font-size: 112px, $line-height: 112px, $font-weight: 300, $font-family: 'Arial', $letter-spacing: -0.05em),
  $headline-2: mat.define-typography-level($font-size: 52px,  $line-height: 56px,  $font-weight: 400, $font-family: 'Arial', $letter-spacing: -0.02em),
  $headline-3: mat.define-typography-level($font-size: 42px,  $line-height: 48px,  $font-weight: 400, $font-family: 'Arial', $letter-spacing: -0.005em),
  $headline-4: mat.define-typography-level($font-size: 34px,  $line-height: 40px,  $font-weight: 400, $font-family: 'Arial'),
  $headline-5: mat.define-typography-level($font-size: 28px,  $line-height: 32px,  $font-weight: 400, $font-family: 'Arial'),
  $headline-6: mat.define-typography-level($font-size: 24px,  $line-height: 32px,  $font-weight: 400, $font-family: 'Arial'),
  $subtitle-1: mat.define-typography-level($font-size: 20px,  $line-height: 32px,  $font-weight: 400, $font-family: 'Arial'),
  $subtitle-2: mat.define-typography-level($font-size: 14px,  $line-height: 32px,  $font-weight: 400, $font-family: 'Arial'),
  $body-1:     mat.define-typography-level($font-size: 16px,  $line-height: 24px,  $font-weight: 400, $font-family: 'Arial'),
  $body-2:     mat.define-typography-level($font-size: 14px,  $line-height: 24px,  $font-weight: 400, $font-family: 'Arial'),
  $caption:    mat.define-typography-level($font-size: 16px,  $line-height: 24px,  $font-weight: 400, $font-family: 'Arial'),
  $button:     mat.define-typography-level($font-size: 16px,  $line-height: 24px,  $font-weight: 400, $font-family: 'Arial'),
);

.mat-typography {
    font-family: 'Arial', cursive;
  }
